import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import URLSearchParams from '@ungap/url-search-params';
import { checkTypeFilter, fetchFilters, setDateFilter, resetFilters } from './investorWarningsActions';
import CheckboxList from '../components/CheckboxList';
import FTYearPicker from '../components/ftYearPicker';
import Loader from '../components/loader';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';
import { window } from '../util/ssrBase';

class InvestorWarningsSearchFilters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            showYears: false
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const inputYear = urlParams.get("from")?.substring(0, 4);
        if (inputYear !== undefined) {
            dispatch(setDateFilter({ id: parseInt(inputYear, 10), fromDate: urlParams.get("from"), toDate: urlParams.get("to") }, true));
        }
        dispatch(fetchFilters(this.context.code));
    }

    render() {
        const { typefilters, dispatch, dateFilters } = this.props;
        if (typefilters.loaded) {
            return (
                <div className="w-full border-t border-b border-l-4 border-r border-bluegreen-300 bg-white-400" >
                    <button
                        title={translateLang('newsarchive.headers.filter', this.context.code)}
                        type="button"
                        className="flex items-center justify-between w-full p-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                        aria-expanded={this.state.showFilters}
                        aria-controls="filterselectors"
                        onClick={() => this.setState({ showFilters: !this.state.showFilters })}
                    >
                        <span>{translateLang('newsarchive.headers.filter', this.context.code)}</span>
                        <span className="material-icons" aria-hidden="true">
                            {this.state.showFilters ? 'close' : 'expand_more'}
                        </span>
                    </button>

                    {this.state.showFilters && (
                        <>
                            <div className='px-4'>
                                <CheckboxList
                                    typefilters={typefilters.items}
                                    onTypeFilterClick={(id, name) => dispatch(checkTypeFilter(id, name, true))}
                                    additionalClass="investor-warning-type-filter-class-name"
                                />
                                <button
                                    title={translateLang('newsarchive.texts.yearFilterDescription', this.context.code)}
                                    type="button"
                                    className="flex items-center justify-between w-full cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                                    aria-expanded={this.state.showYears}
                                    aria-controls="yearselector"
                                    onClick={() => this.setState({ showYears: !this.state.showYears })}
                                >
                                    <span>{translateLang('newsarchive.texts.yearFilterDescription', this.context.code)}</span>
                                    <span className="material-icons" aria-hidden="true">
                                        {this.state.showYears ? 'close' : 'expand_more'}
                                    </span>
                                </button>
                                {this.state.showYears && (
                                    <FTYearPicker
                                        language={this.context.code}
                                        dateFilters={dateFilters}
                                        onDateSet={(dateFilters) => dispatch(setDateFilter(dateFilters, true))}
                                    />
                                )}
                            </div>
                            <div className="flex justify-between p-4">
                                <button type="button" title={translateLang('newsarchive.buttons.resetFilter', this.context.code)} className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                    onClick={() => dispatch(resetFilters())}
                                >
                                    <span className="underline group-hover:no-underline">{translateLang('newsarchive.buttons.resetFilter', this.context.code)}</span>
                                </button>
                                <button
                                    type="button" title={translateLang('newsarchive.buttons.closeFilter', this.context.code)} className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                    onClick={() => this.setState({ showFilters: false })}
                                >
                                    <span className="underline group-hover:no-underline">
                                        {translateLang('newsarchive.buttons.closeFilter', this.context.code)}
                                    </span>
                                    <span className="ml-2 material-icons" aria-hidden="false">close</span>
                                </button>
                            </div>
                        </>
                    )}

                    {this.state.showFilters === false && (typefilters?.items?.some(f => f.checked === true) || dateFilters?.id !== -1) && (
                        <section className="border-t border-black-200 mb-4 mx-4 pt-2">
                            {typefilters?.items?.some(f => f.checked === true) &&
                                <>
                                    <p className="text-sm mb-1">
                                        {translateLang('newsarchive.texts.selectedNewsTypes', this.context.code)}
                                    </p>
                                    <ul className="-mx-1 flex flex-wrap">
                                        {typefilters.items.map(filter => {
                                            if (filter.checked === false) return null;

                                            return (<li className="p-1" key={filter.id}>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        dispatch(checkTypeFilter(filter.id, filter.name, true));
                                                    }}
                                                    className="text-sm bg-bluegreen-100 p-1 icon icon--close after:text-bluegreen-500 after:content-['close'] after:ml-0 hover:text-bluegreen-500 hover:bg-bluegreen-100">
                                                    {filter.name}
                                                </button>
                                            </li>);
                                        }
                                        )}
                                    </ul>
                                </>
                            }

                            {dateFilters?.id !== -1 && dateFilters?.id !== 1980 &&
                                <p className="text-sm mb-1">
                                    {translateLang('newsarchive.texts.filteredForPeriod', this.context.code, [dateFilters.id])}
                                </p>}
                            {dateFilters?.id === 1980 &&
                                // This is the ...and older selection
                                <p className="text-sm mb-1">
                                    {translateLang('newsarchive.texts.filteredForPeriodAndOlder', this.context.code, [dateFilters.toDate.substring(0, 4)])}
                                </p>}
                        </section>
                    )}
                </div>
            );
        }

        return (<Loader isLoading languageCode={this.context.code} />);

    }
}

InvestorWarningsSearchFilters.propTypes = {
    typefilters: PropTypes.object.isRequired,
    dateFilters: PropTypes.object.isRequired
};

InvestorWarningsSearchFilters.contextType = LanguageContext;

function mapStateToProps(state) {
    return {
        typefilters: state.typeFilters,
        dateFilters: state.dateFilters
    };
}

export default connect(mapStateToProps)(InvestorWarningsSearchFilters);
