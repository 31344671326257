import React, { FC, useContext } from "react"
import { ReportJsonEntry } from "../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg.Reports"
import { translateLang } from "../../translate"
import { LanguageContext } from "../../components/LanguageContext"

interface ReportEntryProps {
    hit: ReportJsonEntry,
    data: string
}

export const NomineeReportResultItem: FC<ReportEntryProps> = (props: ReportEntryProps) => {
    const languageContext = useContext(LanguageContext);
    return (
        <div className="p-6 bg-white-400 mb-3">
            <div key={props.hit.licenceOwner?.finanstilsynetId}>
                {props.hit.licenceOwner.serviceProviderType &&

                    <div className="text-sm text-yellow-600">
                        {translateLang(`vreg.serviceProvider.types.${props.hit.licenceOwner.serviceProviderType}`, languageContext.code)}
                    </div>
                }

                <h2 className=" text-xl font-semibold">
                    <a href={`${props.data}?id=${props.hit.licenceOwner.legalEntityId}`} title={props.hit.licenceOwner.legalEntityName} className=" text-black-400 hover:text-bluegreen-500 uppercase">
                        {props.hit.licenceOwner.legalEntityName}
                    </a>
                </h2>

                <ul className="flex flex-wrap lg:flex-nowrap flex-row mt-2 -mx-3 -mb-3">
                    <li className="flex items-center space-x-1 p-3">
                        <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">corporate_fare</span>
                        <span className="text-yellow-600 uppercase text-base font-semibold">
                            {translateLang(`vreg.entityTypes.${props.hit.licenceOwner.legalEntityType.toLowerCase()}`, languageContext.code)}
                        </span>
                    </li>
                    {props.hit.licence &&
                        <li className="flex items-center space-x-1 p-3">
                            <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">list_alt</span>
                            <span className="text-yellow-600 uppercase text-base font-semibold">
                                {props.hit.licence.licenceTypeName}
                            </span>
                        </li>
                    }
                    {props.hit.licenceOwner.country &&
                        <li className="flex items-center space-x-1 p-3">
                            <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">public</span>
                            <span className="text-yellow-600 uppercase text-base font-semibold">
                                {props.hit.licenceOwner.country}
                            </span>
                        </li>
                    }
                    {props.hit.licenceOwner.county &&
                        <li className="flex items-center space-x-1 p-3">
                            <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">location_pin</span>
                            <span className="text-yellow-600 uppercase text-base font-semibold">
                                {props.hit.licenceOwner.county}
                            </span>
                        </li>
                    }
                </ul>
            </div>

            <div className="block bg-black-100 h-1 my-6" />

            <div className="flex flex-col lg:flex-row lg:flex-wrap lg:-mx-3 -mt-3" >
                {props.hit.licenceOwner.legalEntityNumber && (
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.orgnumber', languageContext.code)}
                        </h2>
                        <p>{props.hit.licenceOwner.legalEntityNumber}</p>
                    </div>
                )}

                {props.hit.licenceOwner.leiCode &&
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.leiCode', languageContext.code)}
                        </h2>
                        <p>{props.hit.licenceOwner.leiCode}</p>
                    </div>
                }

                {!props.hit.licenceOwner.legalEntityId && props.hit.licenceOwner.legalEntityType !== "Person" && props.hit.licenceOwner.finanstilsynetId &&
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            Finanstilsynet Id
                        </h2>
                        <p>{props.hit.licenceOwner.finanstilsynetId}</p>
                    </div>
                }

                {props.hit.licenceOwner.auditorNumber && (
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.auditorNumber', languageContext.code)}
                        </h2>
                        <p>{props.hit.licenceOwner.auditorNumber}</p>
                    </div>
                )}

                {props.hit.hasOtherFinancialInstruments && (
                    <div className="py-3 lg:px-3">
                    <h2 className="text-base font-semibold mb-2">
                        {translateLang('vreg.reports.nominee.hasOtherFinancialInstruments', languageContext.code)}
                    </h2>
                    <p>
                        <span className="icon icon--custom">check</span>
                    </p>               
                </div>
                )}

                {props.hit.hasShares && (
                    <div className="py-3 lg:px-3">
                    <h2 className="text-base font-semibold mb-2">
                        {translateLang('vreg.reports.nominee.hasShares', languageContext.code)}
                    </h2>                    
                    <p>
                        <span className="icon icon--custom">check</span>
                    </p>
                </div>
                )}

                {props.hit.hasStocks && (
                    <div className="py-3 lg:px-3">
                    <h2 className="text-base font-semibold mb-2">
                        {translateLang('vreg.reports.nominee.hasStocks', languageContext.code)}
                    </h2>
                    <p>
                        <span className="icon icon--custom">check</span>
                    </p>
                </div>
                )}  

            </div>
        </div>
    );
}