import { trackEvent } from '../eventTracking';
import { clearSessionStorage, getSessionStorageResult } from '../util/persistScroll';
import { mapToUrlParams, replaceUrlParams } from '../util/urlHelpers';

export const FILTERS_LOADED = 'FILTERS_LOADED';
export const SEARCH_LOADED = 'SEARCH_LOADED';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const LOADED_MORE = 'LOADED_MORE';
export const CHECK_TYPEFILTER = 'CHECK_TYPEFILTER';
export const SET_DATE_FILTERS = 'SET_DATE_FILTERS';
export const SORT_ALPHABETICALLY = 'SORT_ALPHABETICALLY';
export const RESET_FILTERS = 'RESET_FILTERS_INVESTORWARNINGS';

function getUrlParams(searchState, page) {
    const { queryString, lang, activeFilters, dateFilters, sortResults } = searchState;
    return mapToUrlParams((addIfNotNull) => {
        addIfNotNull('query', queryString);
        addIfNotNull('language', lang);
        addIfNotNull('page', page);
        activeFilters.map((filter) => addIfNotNull('types', filter));
        addIfNotNull('from', dateFilters.fromDate);
        addIfNotNull('to', dateFilters.toDate);
        addIfNotNull('sortAlphabetically', sortResults.sortAlphabetically);
    });
}

export function checkTypeFilter(id, name, isChanged) {
    trackEvent('Filtrer type', name);

    // Clear sessionStorage hvis query er endret for å få nye resultater
    if (isChanged) {
        clearSessionStorage();
    }

    return {
        type: CHECK_TYPEFILTER,
        id,
    };
}

export function setDateFilter(dateFilters, isChanged) {
    trackEvent('Filtrer dato', dateFilters.name);

    // Clear sessionStorage hvis query er endret for å få nye resultater
    if (isChanged) {
        clearSessionStorage();
    }

    return {
        type: SET_DATE_FILTERS,
        dateFilters,
    };
}

export function sortAlphabetically(sortAlphabetically) {
    trackEvent('Sorter alfabetisk', sortAlphabetically);
    return {
        type: SORT_ALPHABETICALLY,
        sortAlphabetically,
    };
}

export function searchLoaded(searchResult) {
    return {
        type: SEARCH_LOADED,
        searchResult,
    };
}

export function filtersLoaded(filters) {
    return {
        type: FILTERS_LOADED,
        filters,
    };
}

export function setSearchQuery(queryString, isChanged) {
    // Clear sessionStorage hvis query er endret for å få nye resultater
    if (isChanged) {
        clearSessionStorage();
    }

    trackEvent('Søk', queryString);
    return {
        type: SET_SEARCH_QUERY,
        queryString,
    };
}

export function fetchFilters(lang) {
    return (dispatch) =>
        fetch(`/api/search/investorwarnings/metadata?l=${lang}`).then((response) =>
            response.json().then((filters) => dispatch(filtersLoaded(filters)))
        );
}

function fetchSearch(searchState) {
    const searchApi = '/api/search/investorwarnings';
    const q = getUrlParams(searchState, searchState.page);
    replaceUrlParams(q);

    const investorWarningResult = getSessionStorageResult('investorWarningResult');

    if (investorWarningResult) {
        return (dispatch) => {
            dispatch(searchLoaded(investorWarningResult));
        };
    }

    return (dispatch) =>
        fetch(searchApi + q).then((response) =>
            response.json().then((result) => dispatch(searchLoaded(result)))
        );
}

export function initSearch(searchState) {
    return (dispatch) => dispatch(fetchSearch(searchState));
}

export function loadedMore(searchResult) {
    trackEvent('Last flere', `Side ${searchResult.page}`);
    return {
    type: LOADED_MORE,
        searchResult,
    };
}

export function loadMore(searchState, currentPage) {
    const searchApi = '/api/search/investorwarnings';
    const q = getUrlParams(searchState, currentPage);
    replaceUrlParams(q);

    return (dispatch) =>
        fetch(searchApi + q).then((response) =>
            response.json().then((result) => dispatch(loadedMore(result)))
        );
}

export function resetFilters()  {
    return (dispatch) => dispatch({type: RESET_FILTERS});
    
}
