import { combineReducers } from 'redux';
import {
    CHECK_TYPEFILTER,
    FILTERS_LOADED,
    LOADED_MORE,
    RESET_FILTERS,
    SEARCH_LOADED,
    SET_DATE_FILTERS,
    SET_SEARCH_QUERY,
    SORT_ALPHABETICALLY,
} from './investorWarningsActions';
import { getLocale } from '../translate';
import { setSessionStorageResult } from '../util/persistScroll';

const initialSettingsState = {
    lang: getLocale(),
};

const initialTypeFiltersState = {
    loaded: false,
    activeFilters: [],
    items: [],
};

const initialDateFiltersState = {
    id: -1,
    fromDate: '',
    toDate: '',
};

const initialSortResultsState = {
    sortAlphabetically: false,
};

const initialSearchResultState = {
    loading: false,
    items: [],
    page: 1
};

const initialSearchBoxState = {
    queryString: '',
};

export const initialState = {
    typeFilters: initialTypeFiltersState,
    dateFilters: initialDateFiltersState,
    searchResult: initialSearchResultState,
    searchBox: initialSearchBoxState,
    settings: initialSettingsState,
    sortResults: initialSortResultsState,
};

const settings = (state = initialSettingsState) => state;

const typeFilter = (state, action) => {
    switch (action.type) {
        case CHECK_TYPEFILTER:
            if (state.id !== action.id) {
                return state;
            }
            return { ...state, checked: !state.checked };        
        default:
            return state;
    }
};

const typeFilters = (state = initialTypeFiltersState, action) => {
    switch (action.type) {
        case CHECK_TYPEFILTER:
            return { ...state, items: state.items.map((item) => typeFilter(item, action)) };
        case RESET_FILTERS:
            return {...state, 
                    activeFilters: [],
                    items: state.items.map((item) => { item.checked = false; return item;})
                };
    case FILTERS_LOADED:
            return {
                ...state,
                loaded: true,
                items: action.filters.map((item) => ({
                    ...item,
                    checked: state.activeFilters.includes(item.id),
                })),
            };

        default:
            return state;
    }
};

const dateFilters = (state = initialDateFiltersState, action) => {
    switch (action.type) {
        case SET_DATE_FILTERS:
            return {
                ...state,
                id: action.dateFilters.id,
                fromDate: action.dateFilters.fromDate,
                toDate: action.dateFilters.toDate,
            };
            case RESET_FILTERS:
                return {...state, ...initialDateFiltersState}
        default:
            return state;
    }
};

const sortResults = (state = initialSortResultsState, action) => {
    switch (action.type) {
        case SORT_ALPHABETICALLY:
            return { ...state, sortAlphabetically: action.sortAlphabetically };
        default:
            return state;
    }
};

const searchResult = (state = initialSearchResultState, action) => {
    switch (action.type) {
        case SEARCH_LOADED:
            return {
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                total: action.searchResult.totalHits,
                pageSize: action.searchResult.pageSize,
                totalPages: action.searchResult.totalPages
            };
        case LOADED_MORE: {
            const obj = action.searchResult;
            if (state.items) {
                obj.Hits = action.searchResult.hits;
            }
            setSessionStorageResult('investorWarningResult', obj);

            return {
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                total: action.searchResult.totalHits,
                pageSize: action.searchResult.pageSize,
                totalPages: action.searchResult.totalPages
            };
        }
        default:
            return state;
    }
};

const searchBox = (state = initialSearchBoxState, action) => {
    switch (action.type) {
        case SET_SEARCH_QUERY:
            return { ...state, queryString: action.queryString };
        default:
            return state;
    }
};

const investorWarningsReducer = combineReducers({
    typeFilters,
    dateFilters,
    searchResult,
    searchBox,
    settings,
    sortResults,
});

export default investorWarningsReducer;
