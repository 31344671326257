import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { VirkeregReportJsonResponse } from '../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg.Reports';
import { VirkeregLicenceType } from '../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg';
import { CheckedChanged, MultiCheckedChanged } from './CheckedChanged';

interface VregUserReportState {
    pageNumber: number
    totalPages: number
    pageSize: number
    reportResult: null | VirkeregReportJsonResponse
    licenceTypes: VirkeregLicenceType[]
    passporting: 'Domestic' | 'Inbound' | 'Outbound',
    countryCode: string|null,
    includedProviderTypes: string[],
    countyNumber: number | null,
    services: CheckedChanged[],
    licenceGroupLicenceTypes: VirkeregLicenceType[]
}

const initialState: VregUserReportState = {
    pageNumber: 1,
    totalPages: 10,
    pageSize: 10,
    reportResult: null,
    licenceTypes: [],
    passporting: 'Domestic',
    countryCode: null,
    includedProviderTypes : [],
    countyNumber: null,
    services: [],
    licenceGroupLicenceTypes: []
};

export const vregUserReportState = createSlice({
    name: 'vregUserReportState',
    initialState,
    reducers: {
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        goToFirstPage: (state) => {
            state.pageNumber = 1;
        },
        incrementPage: (state) => {
            state.pageNumber += 1;
        },
        decrementPage: (state) => {
            state.pageNumber -= 1;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
        },
        setReportResult: (state, action: PayloadAction<VirkeregReportJsonResponse>) => {
            state.reportResult = action.payload
            state.totalPages = action.payload.totalPages
        },
        toggleLicenceType: (state, action: PayloadAction<VirkeregLicenceType>) => {
            const existingLicence = state.licenceTypes.findIndex(f => f.code === action.payload.code);
            if(existingLicence > -1){
                state.licenceTypes.splice(existingLicence,1)
            }
            else{
                state.licenceTypes.push(action.payload);
            }
        },
        setPassporting: (state, action: PayloadAction<'Domestic' | 'Inbound' | 'Outbound'>) => {
            state.passporting = action.payload
        },
        setCountry: (state, action: PayloadAction<string|null>) => {
            state.countryCode = action.payload
        },
        setIncludedProviderTypes: (state, action: PayloadAction<string>) => {
            const itemIndex = state.includedProviderTypes.indexOf(action.payload)
            if(itemIndex > -1)
                state.includedProviderTypes.splice(itemIndex, 1)
            else
                state.includedProviderTypes.push(action.payload)
        },
        setCountyNumber: (state, action: PayloadAction<number>) =>{
            state.countyNumber = action.payload
        },
        toggleService: (state, action: PayloadAction<CheckedChanged>) => {
            const existingIndex = state.services.findIndex(a => a.id === action.payload.id);
            if (existingIndex > -1) {
                state.services.splice(existingIndex, 1);
            }
            else{
                state.services.push(action.payload);
            }            
        },
        toggleServiceGroup: (state, action: PayloadAction<MultiCheckedChanged>) => {
            if (action.payload.checked) {
                action.payload.ids.map((type) => {
                    const existingIndex = state.services.indexOf(type);
                    if (existingIndex === -1) state.services.push(type);
                    return type;
                });
            } else {                
                const unwantedIds = action.payload.ids.map(a => a.id);
                state.services = state.services.filter(f => unwantedIds.indexOf(f.id) === -1);
            }
        },
        setAvailableLicenceGroupTypes: (state, action: PayloadAction<VirkeregLicenceType[]|undefined>) => {
            if(action.payload !== undefined)
            state.licenceGroupLicenceTypes = action.payload;
        }
    },
});

export const vregUserReportStateReducer = vregUserReportState.reducer;
export const vregUserReportStateActions = vregUserReportState.actions;