import React, { FC, useContext } from 'react'
import { LanguageContext } from '../../../components/LanguageContext'
import { ProviderTypeFilter } from './ProviderTypeFilter';
import { translateLang } from '../../../translate';
import { useGetLicenceGroupsQuery } from '../../Services/VregApi';
import { ApiRequest } from '../../Services/ApiRequest';
import { window } from '../../../util/ssrBase';

export const Filters: FC = () => {

  const languageContext = useContext(LanguageContext);
  const queryParams = new URLSearchParams(window.location.search);
  const code = parseInt(queryParams.get("group") ?? "11", 10); // default to show "Bank and finance" if group parameter is not set
  const { data } = useGetLicenceGroupsQuery({ language: languageContext.code } as ApiRequest);

  return (

    <aside className="w-full lg:w-4/12">

      {/* <!-- Placeholder for mobile vs desktop - brace yourself this might be ugly code --> */}
      <div className="fixed lg:relative bg-[rgba(40,40,40,0.8)] lg:bg-transparent inset-0 hidden lg:block">
        <div className="absolute z-50 lg:relative bottom-0 left-0 h-[85vh] lg:h-auto w-full bg-white-400 lg:bg-transparent rounded-t-[20px] lg:block">
          {/* <!-- Mobile header --> */}
          <div className="w-full lg:hidden flex flex-col pt-3 mb-4 px-6 items-center">
            <button type="button" aria-label="Lukk filter" className="w-10 h-2 bg-black-200 rounded-lg mb-7">
              <span className="sr-only">{translateLang('vreg.reports.filter', languageContext.code)}</span>
            </button>
            <h2 className="font-semibold text-base border-b border-black-200 pb-6 w-full text-center">
              {translateLang('vreg.reports.filter', languageContext.code)}
            </h2>

            {/* <!-- Num hits --> */}
            <div className="lg:hidden text-sm px-6 my-6">
              <strong>115</strong> treff på &quot;Bank&quot;
            </div>
            {/* <!-- // Num hits --> */}
          </div>
          {/* <!-- // Mobile header --> */}

          <div className="h-[calc(85vh-180px)] overflow-scroll lg:h-auto lg:overflow-hidden px-6 lg:px-0">
            <ProviderTypeFilter />            
          </div>
        </div>
      </div>
      {/* <!-- // Placeholder for mobile vs desktop - brace yourself this might be ugly code --> */}

      {/* <!-- Download --> */}
      <div className="bg-white-400 p-6 mb-6">
        {translateLang('common.comingSoon', languageContext.code)}
        <span aria-disabled='true' title="Last ned" className="btn btn--primary btn--disabled w-full text-center block icon icon--file__download">
          {translateLang('vreg.reports.download', languageContext.code)}
        </span>
      </div>
      {/* <!-- // Download --> */}

      {/* <!-- Other pages --> */}
      <div className="bg-white-400 p-6 mb-6">
        <h2 className=" font-semibold text-base mb-5">
          {translateLang('vreg.reports.otherAreas', languageContext.code)}
        </h2>

        <ul className="flex flex-col space-y-2">
        {data?.filter(f => f.id !== code).map(licenceType => 
          <li key={`licenceType_${licenceType.id}`}>
          <a href={`?group=${licenceType.id}`} title={licenceType.name} aria-label={licenceType.name} className="text-bluegreen-500 hover:no-underline underline decoration-1 underline-offset-4 icon icon--chevron__right">
            {licenceType.name}
          </a>
        </li>
        )}
        </ul>

      </div>


    </aside>)
}