import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import URLSearchParams from '@ungap/url-search-params';
import CheckboxList from '../components/CheckboxList';
import FTYearPicker from '../components/ftYearPicker';
import Loader from '../components/loader';
import { translateLang } from '../translate';
import * as supervision from '../components/subjectSelector/supervision';
import { checkMetaFilter, checkTypeFilter, fetchFilters, resetFilters, setDateFilter, setSavedSupervisionCategories } from './actions';
import { LanguageContext } from '../components/LanguageContext';
import { window } from '../util/ssrBase';

function getMetaDataFilters(typeFilters, metaFilters, isChecked, lang) {
    if (typeFilters.loaded) {        
        const active = typeFilters.items.filter(isChecked);
        if (active.length === 1) {
            const filteredItems = metaFilters.items.filter((item) => item.type === active[0].type);
            if (filteredItems.length > 0) {                
                return {
                    type: translateLang(`newsarchive.select.${active[0].type}`, lang),
                    items: filteredItems,
                    activeFilter: metaFilters.activeFilter
                };
            }
        }
    }
    return { type: '', items: [] };
}

class NewsArchiveSearchFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SearchStateUrlVisible: false,
            showFilters: true,
            showYears: false            
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        
        const urlParams = new URLSearchParams(window.location.search);
        const inputYear = urlParams.get("from")?.substring(0,4);
        if(inputYear !== undefined){
            dispatch(setDateFilter({id: parseInt(inputYear, 10), fromDate: urlParams.get("from"), toDate: urlParams.get("to") }, true));
        }

        const dispatchSupervisionCategories = function f(items) {
            dispatch(setSavedSupervisionCategories(items));
        };

        supervision.bootstrap(dispatchSupervisionCategories);

        dispatch(fetchFilters(this.context.code));

    }

    render() {
        const { filters, metafilters, dateFilters, dispatch } = this.props;

        const isChecked = (item) => filters.activeFilters.includes(item.id);

        const metaDataFilters = getMetaDataFilters(filters, metafilters, isChecked, this.context.code);

        if (filters.loaded) {
            return (
                <div className="w-full border-t border-b border-l-4 border-r border-bluegreen-300 bg-white-400 mb-6" >
                    <button
                        title={translateLang('newsarchive.headers.filter', this.context.code)}
                        type="button"
                        className="flex items-center justify-between w-full p-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                        aria-expanded={this.state.showFilters}
                        aria-controls="filterselectors"
                        onClick={() => this.setState({ showFilters: !this.state.showFilters })}
                    >
                        <span>{translateLang('newsarchive.headers.filter', this.context.code)}</span>
                        <span className="material-icons" aria-hidden="true">
                            {this.state.showFilters ? 'close' : 'expand_more'}
                        </span>
                    </button>
                    {this.state.showFilters && (
                        <div className='px-4'>
                            <div id="subjectareaselector" aria-hidden="false">
                                <CheckboxList
                                    typefilters={filters.items.map((item) => ({
                                        checked: isChecked(item),
                                        ...item,
                                    }))}
                                    onTypeFilterClick={(id, name) => dispatch(checkTypeFilter(id, name, true))}
                                    metaDataFilters={metaDataFilters}
                                    onMetaFilterChange={(name, selected) => dispatch(checkMetaFilter(name, selected, true))}
                                />

                                <button
                                    title={translateLang('newsarchive.texts.yearFilterDescription', this.context.code)}
                                    type="button"
                                    className="flex items-center justify-between w-full pb-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                                    aria-expanded={this.state.showYears}
                                    aria-controls="yearselector"
                                    onClick={() => this.setState({ showYears: !this.state.showYears })}
                                >
                                    <span>{translateLang('newsarchive.texts.yearFilterDescription', this.context.code)}</span>
                                    <span className="material-icons" aria-hidden="true">
                                        {this.state.showYears ? 'close' : 'expand_more'}
                                    </span>
                                </button>
                                {this.state.showYears && (
                                    <FTYearPicker
                                        dateFilters={dateFilters}
                                        onDateSet={(dateFilters) => dispatch(setDateFilter(dateFilters, true))}
                                        language={this.context.code}
                                    />
                                )}
                            </div>
                            <div className="flex justify-between py-4">
                                <button type="button" title="Fjern valgte" className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                    onClick={() => dispatch(resetFilters())}
                                >
                                    <span className="underline group-hover:no-underline">{translateLang('newsarchive.buttons.resetFilter', this.context.code)}</span>
                                </button>
                                <button
                                    type="button" title="Lukk" className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                    onClick={() => this.setState({ showFilters: false })}
                                >
                                    <span className="underline group-hover:no-underline">
                                        {translateLang('newsarchive.buttons.closeFilter', this.context.code)}
                                    </span>
                                    <span className="ml-2 material-icons" aria-hidden="false">close</span>
                                </button>
                            </div>
                        </div>
                    )}

                    {this.state.showFilters === false && (filters.activeFilters?.length > 0 || dateFilters?.id !== -1) && (
                        <section className="border-t border-black-200 mb-4 mx-4 pt-2">
                            {filters.activeFilters?.length > 0 &&
                                <>
                                    <p className="text-sm mb-1">
                                        {translateLang('newsarchive.texts.selectedNewsTypes', this.context.code)}
                                    </p>
                                    <ul className="-mx-1 flex flex-wrap mb-1">
                                        {filters.activeFilters.map(filter =>
                                            <li className="p-1" key={filter}>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const filterObject = filters.items.find(f => f.id === filter);
                                                        dispatch(checkTypeFilter(filter, filterObject.name, true));
                                                    }}
                                                    className="text-sm bg-bluegreen-100 p-1 icon icon--close after:text-bluegreen-500 after:content-['close'] after:ml-0 hover:text-bluegreen-500 hover:bg-bluegreen-100">
                                                    {filters.items.find(f => f.id === filter).name}
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </>}
                            {dateFilters?.id !== -1 && dateFilters?.id !== 1980 &&
                                <p className="text-sm mb-1">
                                    {translateLang('newsarchive.texts.filteredForPeriod', this.context.code, [dateFilters.id])}
                                </p>}
                            {dateFilters?.id === 1980 &&
                            // This is the ...and older selection
                            <p className="text-sm mb-1">
                                {translateLang('newsarchive.texts.filteredForPeriodAndOlder', this.context.code, [dateFilters.toDate.substring(0,4)])}
                            </p>}
                        </section>
                    )
                    }
                </div >
            );
        }

        return <Loader isLoading languageCode={this.context.code} />;

    }
}

NewsArchiveSearchFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    metafilters: PropTypes.object.isRequired,
    dateFilters: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        filters: state.typeFilters,
        metafilters: state.metaDataFilters,
        dateFilters: state.dateFilters
    };
}


NewsArchiveSearchFilters.contextType = LanguageContext;

export default connect(mapStateToProps)(NewsArchiveSearchFilters);
