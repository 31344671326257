import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import URLSearchParams from '@ungap/url-search-params';
import InvestorWarningsSearchBox from './investorWarningsSearchBox';
import InvestorWarningsSearchFilters from './InvestorWarningsSearchFilters';
import InvestorWarningsSearchResults from './InvestorWarningsSearchResults';
import InvestorWarningSearchState from './investorWarningsSearchState';
import investorWarningsReducer, { initialState } from './investorWarningsReducer';
import { setScrollPosition } from '../util/persistScroll';
import { window } from '../util/ssrBase';
import { LanguageContext, getLanguage } from '../components/LanguageContext';

export const NAME = 'investorwarnings';

function populateInitialState(language) {
    if (window.location.search) {
        const params = new URLSearchParams(window.location.search.substring(1));
        initialState.searchBox.queryString = params.get('q') || '';
        initialState.typeFilters.activeFilters =
            params.getAll('t').map((t) => parseInt(t, 10)) || [];
        initialState.dateFilters.fromDate = params.get('from') || '';
        initialState.dateFilters.toDate = params.get('to') || '';
        initialState.sortResults.sortAlphabetically = params.get('sortAlphabetically') || false;
    }
    initialState.settings.lang = language;
}

export class InvestorWarnings extends React.Component {
    store = null;

    constructor(props) {
        super(props);
        setScrollPosition(NAME);

        populateInitialState(this.props.language);

        this.store = createStore(investorWarningsReducer, initialState, applyMiddleware(thunk));

    }

    render() {
        return (
            <LanguageContext.Provider value={ getLanguage(this.props.language)}>
                <Provider store={this.store}>
                    <InvestorWarningSearchState />

                    <div className="flex justify-end mb-6">
                        <div className="w-full lg:w-8/12 lg:pl-5">
                            <h1 className="text-2xl lg:text-3xl font-normal mb-6">{this.props.pageName}</h1>
                            <div className="flex w-full ">
                                <InvestorWarningsSearchBox />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                        <aside className="w-full lg:w-4/12 relative">

                            <div className="mb-6">
                                <InvestorWarningsSearchFilters />
                            </div>
                        </aside>

                        <div className="w-full lg:w-8/12">
                            <InvestorWarningsSearchResults />
                        </div>
                    </div>

                </Provider>
            </LanguageContext.Provider>
        )
    }
}

