import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const CheckboxListItem = ({ additionalClass, checked, text, onClick }) => (
    <li className={classNames('search-filter-checkbox-container checkbox--custom', additionalClass)}>
        <input
            type="checkbox"
            checked={checked}
            id={text}
            className="search-filter-checkbox"
            onChange={onClick}
            title={text}
        />
        <label htmlFor={text}>{text}</label>
    </li>
);

CheckboxListItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    additionalClass: PropTypes.string
};

export default CheckboxListItem;
