import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { setScrollPosition, scrollToPrevPosition } from '../util/persistScroll';
import NewsArchiveSearchResults from './searchResults';
import { getLanguage, LanguageContext } from '../components/LanguageContext';
import newsarchiveReducer, { initialState } from './reducer';
import { NewsArchiveHeader } from './header';
import { NewsArchiveFilters } from './NewsArchiveFilters';
import * as supervision from '../components/subjectSelector/supervision';
import { window } from '../util/ssrBase';

export const NAME = 'newsarchive';


function populateInitialState(language) {
    if (window.location.search) {
        const params = new URLSearchParams(window.location.search.substring(1));
        initialState.page = params.get('page') || 1;
        initialState.searchBox.queryString = params.get('query') || '';
        initialState.typeFilters.activeFilters =
            params.getAll('t').map((t) => parseInt(t, 10)) ||[];
        initialState.metaDataFilters.activeFilter = params.get('metaData') || -1;
        initialState.dateFilters.fromDate = params.get('from') || '';
        initialState.dateFilters.toDate = params.get('to') || '';
    }
    initialState.supervisionCategories.activeItems = supervision.getSavedSupervisionCategories();
    initialState.settings.lang = language;  
    
}

export class NewsArchive extends React.Component {

    store = null;

    constructor(props) {
        super(props);
        setScrollPosition(NAME);

        this.store = createStore(
            newsarchiveReducer,
            populateInitialState(this.props.language),
            compose(
                applyMiddleware(thunk)
            )
        )

    };

    componentDidMount() {
        scrollToPrevPosition(NAME); 
    };

    render() {
        return (
            <LanguageContext.Provider value={ getLanguage(this.props.language)}>
                <Provider store={this.store}>
                    <NewsArchiveHeader pageName={this.props.pageName} />

                    <div className='flex flex-col lg:flex-row lg:space-x-6'>
                        <aside className='w-full lg:w-4/12'>                           
                                <NewsArchiveFilters language={this.props.language} subjectSelectorDropdownText={this.props.subjectSelectorDropdownText} />
                        </aside>

                        <div className='w-full lg:w-8/12'>
                            <NewsArchiveSearchResults />
                        </div>
                    </div>
                </Provider>
            </LanguageContext.Provider>
        );
    }
}