import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { translateLang } from '../../../translate';
import { LanguageContext } from '../../../components/LanguageContext';
import { vregSearchActions } from '../../Services/VregSearchState';
import { LegalEntityType } from '../../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg/legalEntityType';

export const RegisterTypeFilter: FC = () => {
    const languageContext = useContext(LanguageContext);
    const dispatch = useDispatch();
    return (
        <div className="w-full bg-white-400 lg:border lg:border-l-4 lg:py-4 lg:px-5 mb-6 lg:border-bluegreen-200">
            {/* <!-- Button - toggle filterpane --> */}
            {/* <!-- Bytt mellom icon--close og icon--expand__more --> */}
            <div className="w-full text-base font-semibold">
                {translateLang('vreg.filters.registrationType', languageContext.code)}
            </div>
            {/* <!-- // Button - toggle filterpane --> */}

            <div className="mt-8">
                <div className="flex flex-col lg:flex-row lg:space-x-8">
                    <div className="radiobox--custom ">
                            <input
                                type="radio"
                                id="all_register_types"
                                value=""
                                name="registerType"
                                defaultChecked
                                onClick={() => dispatch(vregSearchActions.setRegisterType(null))}
                            />
                        <label htmlFor="all_register_types">
                            {translateLang('vreg.entityTypes.all', languageContext.code)}
                        </label>
                    </div>
                    <div className="radiobox--custom ">
                            <input
                                type="radio"
                                id="person"
                                value="1"
                                name="registerType"
                                onClick={() =>
                                    dispatch(
                                        vregSearchActions.setRegisterType(LegalEntityType.Person)
                                    )
                                }
                            />
                        <label htmlFor="person">
                            {translateLang('vreg.entityTypes.person', languageContext.code)}
                        </label>
                    </div>

                    <div className="radiobox--custom">
                        
                            <input
                                type="radio"
                                id="foretak"
                                value="0"
                                name="registerType"
                                onClick={() =>
                                    dispatch(
                                        vregSearchActions.setRegisterType(LegalEntityType.Company)
                                    )
                                }
                            />
                            <label htmlFor="foretak">
                                {translateLang('vreg.entityTypes.company', languageContext.code)}
                            </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
