import React, { FC, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../components/LanguageContext";
import { useGetUserReportQuery } from "../Services/VregApi";
import { ReportApiRequest } from "../Services/ReportApiRequest";
import { translateLang } from "../../translate";
import { VregReportRequest } from "../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg.Reports";
import { ReportResultItem } from "./ReportResultItem";
import { VregRootState } from "../Search/vregSearchStore";
import { UserReportPaginator } from "./UserReportPaginator";
import { SkeletonLoader } from "../../components/SkeletonLoader";
import { vregUserReportStateActions } from "../Services/VregUserReportState";

export const FilterResults: FC = () => {

    const languageContext = useContext(LanguageContext);
    const state = useSelector((s: VregRootState) => s.vregUserReportState);    
    const dispatch = useDispatch();
   
    const { data, error, isLoading, isFetching } = useGetUserReportQuery({
        body: {
            licenceCodes: state.licenceTypes.length > 0 ? state.licenceTypes.map(m => m.code) : state.licenceGroupLicenceTypes.map(m => m.code),
            serviceIds: state.services.map(f => parseInt(f.id,10)) || [],
            countyNumber: state.countyNumber,
            passporting: state.passporting,
            countryCode: state.passporting !== 'Domestic' ? state.countryCode : null,
            includedProviderTypes: state.includedProviderTypes,
            pageSize: state.pageSize,
            page: state.pageNumber
        } as VregReportRequest,
        language: languageContext.code
    } as ReportApiRequest);

    useEffect(() => {
        dispatch(vregUserReportStateActions.setTotalPages(data?.totalPages || 1));

        if((data?.totalPages ?? 1) < state.pageNumber){ // reset paging if query/filter has changed 
            dispatch(vregUserReportStateActions.goToFirstPage());
        }

    }, [data]);

    if (error) {
        return <h2>{translateLang('vreg.reports.errorLoading', languageContext.code)}</h2>
    }

    if (isFetching || isLoading) {
        return (
            <div className="w-full lg:w-8/12">
                <SkeletonLoader type="list" />
            </div>
        )
    }

    return (
        <div className="w-full lg:w-8/12">
            {/* <!-- Toggle filter for mobile --> */}
            <div className="lg:hidden bg-white-400 px-6 py-2 mb-6">
                <button type="button" className="btn btn--secondary" aria-label="Filter">
                    <span className="material-icons" aria-hidden="true">filter_alt</span>
                    Filter
                </button>
            </div>
            {/* <!-- // Toggle filter for mobile --> */}

            {/* <!-- Search result summary --> */}
            <div className="px-6 py-3 bg-white-400 mb-6 text-sm leading-5">
                {translateLang('vreg.reports.showingHitsXofY', languageContext.code, [state.pageNumber, data?.totalPages, data?.totalHits])}

                <br />
                {translateLang('vreg.reports.tooltips.selectServicesToImproveResult', languageContext.code)}

            </div>
            {/* <!-- // Search result summary --> */}

            
            {data !== undefined && data.reportData.map(hit =>
                <ReportResultItem
                    key={`${hit.serviceProvider.finanstilsynetId}_${hit.licence.licenceTypeCode}_${hit.borderCrossingActivity}_${hit.licence.service?.id ?? -1}`}
                    data={data.detailPageUrl}
                    hit={hit} />
            )}

            <UserReportPaginator />

        </div>)

}