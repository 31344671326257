import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LegalEntityType } from '../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg';
import { CheckedChanged, MultiCheckedChanged } from './CheckedChanged';

interface VregSearchState {
    selectedLicenceTypes: CheckedChanged[];
    query: string;
    registerType: LegalEntityType | null;
    pageNumber: number;
    totalPages: number;
    country: string | null;
    selectedCounties: CheckedChanged[];
    mobileFilterOpen: boolean
}

const initialState: VregSearchState = {
    selectedLicenceTypes: [],
    query: '',
    registerType: null,
    pageNumber: 1,
    totalPages: 10,
    country: null,
    selectedCounties: [],
    mobileFilterOpen: false
};

export const vregSearchStateSlice = createSlice({
    name: 'vregSearchState',
    initialState,
    reducers: {
        resetLicenceTypes: (state) => {
            state.selectedLicenceTypes = [];
        },
        selectLicenceType: (state, action: PayloadAction<CheckedChanged>) => {
            const existingIndex = state.selectedLicenceTypes.findIndex(a => a.id === action.payload.id);
            if (existingIndex > -1) {
                state.selectedLicenceTypes.splice(existingIndex, 1);
            }
            else{
                state.selectedLicenceTypes.push(action.payload);
            }            
        },
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        goToFirstPage: (state) => {
            state.pageNumber = 1;
        },
        incrementPage: (state) => {
            state.pageNumber += 1;
        },
        decrementPage: (state) => {
            state.pageNumber -= 1;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
        },
        setRegisterType: (state, action: PayloadAction<LegalEntityType | null>) => {
            state.registerType = action.payload;
        },
        setCountry: (state, action: PayloadAction<string | null>) => {
            state.country = action.payload;
        },
        selectAllOrNoCounty: (state) => {
            state.selectedCounties = [];
        },
        toggleAllGroup: (state, action: PayloadAction<MultiCheckedChanged>) => {
            if (action.payload.checked) {
                action.payload.ids.map((type) => {
                    const existingIndex = state.selectedLicenceTypes.indexOf(type);
                    if (existingIndex === -1) state.selectedLicenceTypes.push(type);
                    return type;
                });
            } else {
                const unwantedIds = action.payload.ids.map(a => a.id);
                state.selectedLicenceTypes = state.selectedLicenceTypes.filter(f => unwantedIds.indexOf(f.id) === -1);                
            }
        },
        selectCounty: (state, action: PayloadAction<CheckedChanged>) => {
            const existingIndex = state.selectedCounties.findIndex(a => a.id === action.payload.id);
            if (existingIndex > -1) {
                state.selectedCounties.splice(existingIndex, 1);
            }
            else{
                state.selectedCounties.push(action.payload);
            }
            
        },
        toggleMobileFilter: (state) => {
            state.mobileFilterOpen = !state.mobileFilterOpen;
        }
    },
});

export const vregSearchReducer = vregSearchStateSlice.reducer;
export const vregSearchActions = vregSearchStateSlice.actions;
