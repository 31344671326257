import React, { FC, useContext, useEffect } from "react"
import { Provider, useDispatch, useSelector } from "react-redux";
import { useGetStandardReportQuery } from "../Services/VregApi";
import Loader from "../../components/loader";
import { VregRootState, store } from "../Search/vregSearchStore";
import { LanguageContext, getLanguage } from "../../components/LanguageContext";
import { StandardReportRequest } from "../Services/StandardReportRequest";
import { window } from "../../util/ssrBase";
import { translateLang } from "../../translate";
import { ReportResultItem } from "./ReportResultItem";
import { vregStandardReportActions } from "../Services/VregStandardReportState";
import { StandardReportPaginator } from "./StandardReportPaginator";
import { NomineeReportResultItem } from "./NomineeReportResultItem";

interface Props {
    language: string,
    title: string
}

export const VregStandardReportWrapper: FC<Props> = (props: Props) =>
    <Provider store={store}  >
        <LanguageContext.Provider value={getLanguage(props.language)}>
            <React.StrictMode>
                <VregStandardReportView {...props} />
            </React.StrictMode>
        </LanguageContext.Provider>
    </Provider>

const VregStandardReportView: FC<Props> = (props) => {
    const languageContext = useContext(LanguageContext);
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("area") ?? "Nominee"; // default to show "Nominee" if area parameter is not set
    const pageNumber = useSelector((state: VregRootState) => state.vregStandardReportState.pageNumber);
    const pageSize = useSelector((state: VregRootState) => state.vregStandardReportState.pageSize);

    const { data, error, isLoading } = useGetStandardReportQuery({ language: props.language, area: code, pageSize, page: pageNumber } as StandardReportRequest);

    const dispatch = useDispatch();
 
    useEffect(() => {
        if (data) {
            dispatch(vregStandardReportActions.setTotalPages(data?.totalPages));
        }
    }, [data]);


    if (isLoading) {
        return (
            <Loader isLoading languageCode={languageContext.code} />
        )
    }

    if (error || data === undefined) {
        return <h2>{translateLang('vreg.reports.errorLoading', languageContext.code)}</h2>
    }


    return (
        <>
            <div className="bg-white-400 p-6 mb-6">
                <h1 className="font-normal text-2xl lg:text-3xl">
                    {props.title}
                </h1>
            </div>

            <div className="flex flex-col lg:flex-row justify-end lg:space-x-6">

                <div className="w-full lg:w-4/12">
                    <div className="bg-white-400 p-6 mb-6">
                        <a href={`/api/virkereg/${languageContext.code}/reports/standard/xlsx?area=${code}`} title="Last ned" download className="btn btn--primary w-full text-center block icon icon--file__download">
                            {translateLang('vreg.reports.download', languageContext.code)}
                        </a>
                    </div>

                </div>

                <div className="w-full lg:w-8/12">
                    <div className="bg-white-400 p-6 mb-6">
                        <h2 className="text-lg mb-4">
                            {translateLang('vreg.reports.businessCount', languageContext.code)}
                        </h2>
                        <p className="text-xl lg:text-2xl font-semibold mb-6">
                            {data.totalHits}
                        </p>
                    </div>

                    <ul>
                        {code === "Nominee" &&
                            data.reportData.map(hit =>
                                <NomineeReportResultItem
                                    key={`${hit.licenceOwner?.legalEntityNumber}`}
                                    data={data.detailPageUrl}
                                    hit={hit} />
                            )}
                        {code !== "Nominee" && data.reportData.map(hit =>
                            <ReportResultItem
                                key={`${hit.serviceProvider?.legalEntityNumber}_${hit.licence?.licenceTypeCode}`}
                                data={data.detailPageUrl}
                                hit={hit} />
                        )}
                    </ul>
                    <StandardReportPaginator />
                </div>

            </div>
        </>
    )
}