import React from "react";
import { translateLang } from "../../translate";
import { LoadMore } from "./loadMore";

interface PaginatorProps {
    language: string,
    onLoadMoreClickPrev(): void,
    onLoadMoreClickNext(): void,
    onJumpToPageClick(page: number): void,
    searchResultPage: number,
    searchResultTotalPages: number,

}

// Inspired by the Terminator movies with Ahnold Schwarzenegger
export const Paginator: React.FC<PaginatorProps> = (props: PaginatorProps): JSX.Element | null => {
    if (props.searchResultTotalPages === 0)
        return null;

    return <div role="region" aria-label={translateLang('newsarchive.buttons.loadMore', props.language)} className="flex flex-row justify-center space-x-6 lg:space-x-8 items-center mt-6 mb-24">
        <LoadMore
            onLoadMoreClick={props.onLoadMoreClickPrev}
            icon="icon--fastrewind"
            text={translateLang('search.buttons.prev', props.language)}
            enabled={props.searchResultPage > 1}
        />
        <div className="flex space-x-6">
            {props.searchResultPage >= 6 &&
                <>
                    <button type="button" className="text-bluegreen-500 semibold hover:underline p-3" onClick={() => props.onJumpToPageClick(1)} key='firstPage'>
                        <span>1</span>
                    </button>
                    <div className="p-3">
                        ...
                    </div></>
            }

            {[-4, -3, -2, -1, 0, 1, 2, 3, 4].map((x) => {
                const currentPage = props.searchResultPage + x;
                if (currentPage === props.searchResultPage)
                    return <span className="text-white-400 bg-bluegreen-500 p-3" key={currentPage}>{currentPage}</span>
                
                if (currentPage > 0 && currentPage <= props.searchResultTotalPages)
                    return (<button type="button" className="text-bluegreen-500 semibold hover:underline p-3" onClick={() => props.onJumpToPageClick(currentPage)} key={currentPage}>
                        <span>{currentPage}</span>
                    </button>)
                

                return null;
            }
            )}

            {props.searchResultPage <= props.searchResultTotalPages - 5 &&
                <>
                    <div className="p-3">
                        ...
                    </div>

                    <button type="button" className="text-bluegreen-500 semibold hover:underline p-3" onClick={() => props.onJumpToPageClick(props.searchResultTotalPages)} key={props.searchResultTotalPages}>
                        <span>{props.searchResultTotalPages}</span>
                    </button>
                </>
            }

        </div>

        <LoadMore
            onLoadMoreClick={props.onLoadMoreClickNext}
            icon="icon--fastforward"
            text={translateLang('search.buttons.next', props.language)}
            enabled={props.searchResultPage < props.searchResultTotalPages}
        />
    </div>
}