import React, { useContext } from "react";
import { LanguageContext } from "../components/LanguageContext";
import { SubjectContainer } from "../components/subjectSelector/subjectSelector2";
import NewsArchiveSearchFilters from './searchFilters';

export const NewsArchiveFilters = (props) =>{ 
    const languageContext = useContext(LanguageContext);
    return (
    <>
        <div className="w-full mb-6">
            <NewsArchiveSearchFilters />
        </div>

        <div className="w-full mb-6">
            <SubjectContainer dropdownText={props.subjectSelectorDropdownText} language={languageContext.code}/>
        </div>
    </>);
}