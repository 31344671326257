import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translateLang } from '../translate';

export default class FTYearPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateList: this.getYearList().map((item) => this.makeNewState(item))
        };

        if (this.props.dateFilters.fromDate && this.props.dateFilters.toDate) {
            const id = this.getDateFilterId(
                this.props.dateFilters.fromDate,
                this.props.dateFilters.toDate
            );
            this.props.dateFilters.id = id;
        }
    }

    getYearList = () => {        
        const years = [];
        const currentYear = new Date().getFullYear();
        const limitYear = currentYear - 9
        for (let i = currentYear; i >= limitYear; i--) {
            if (i === limitYear) {
                years.push({
                    id: i,
                    fromYear: 1980,
                    toYear: limitYear,
                    name: `${limitYear} ${translateLang('newsarchive.select.andOlderText',  this.props.language)}`,
                });
            } else {
                years.push({ id: i, fromYear: i, toYear: i, name: i });
            }
        }

        return years;
    };

    getDateFilterId(fromDate, toDate) {
        const selectedYear = this.state.dateList.filter(
            (item) => item.fromDate === fromDate && item.toDate === toDate
        )[0];
        return selectedYear.id;
    }

    setSelectedDate(dateId) {
        return (
            this.state.dateList.find((item) => parseInt(item.id) === parseInt(dateId)) || {
                id: -1,
                fromDate: '',
                toDate: '',
            }
        );
    }

    makeNewState(selectedYear) {
        if (!selectedYear) return { id: -1, fromDate: '', toDate: '' };
        
        const fromDate = moment().year(selectedYear.fromYear).startOf('year');
        const toDate = moment().year(selectedYear.toYear).endOf('year');
        const newState = {
            id: selectedYear.id,
            fromDate: fromDate.format('YYYY-MM-DD'),
            toDate: toDate.format('YYYY-MM-DD'),
            name: selectedYear.name,
        };

        return newState;
    }

    render() {
        return (
            <div className="mt-2 mb-2">
                <ul className="flex flex-wrap -m-3">
                    <li className="radiobox--custom m-3">
                        <input
                            type="radio"
                            name="ftYear"
                            value="-1"
                            id="ftYearDefault"
                            checked={this.props.dateFilters.id === -1}
                            onChange={(e) => this.props.onDateSet(this.setSelectedDate(e.target.value))}                            
                        />
                        <label htmlFor="ftYearDefault">
                            {translateLang('newsarchive.buttons.chooseYear', this.props.language)}
                        </label>
                    </li>

                    {this.state.dateList.map((year) => (
                        <li className="radiobox--custom m-3" key={year.id}>
                            <input
                                type="radio"
                                name="ftYear"
                                value={year.id}
                                id={`yearRadio_${year.id}`}
                                checked={this.props.dateFilters.id === year.id}
                                onChange={(e) => this.props.onDateSet(this.setSelectedDate(e.target.value))}
                            />
                            <label htmlFor={`yearRadio_${year.id}`}>
                                {year.name}
                            </label>
                        </li>
                    ))
                    }
                </ul>
            </div>
        );
    }
}

FTYearPicker.propTypes = {
    dateFilters: PropTypes.object,
    onDateSet: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired
};
