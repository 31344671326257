import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { loadMore } from './investorWarningsActions';
import SearchResults from '../components/search/searchResults';
import { searchStateSelector } from './investorWarningsSearchStateSelector';
import { trackEvent } from '../eventTracking';
import { Paginator } from '../components/search/Paginator';

const InvestorWarningsSearchResults = ({ searchResult, searchState, dispatch, language }) => {
    const canLoadMore = searchResult
        && (searchResult.totalPages > 1)
        && (searchResult.totalPages >= searchResult.page);

    return (
        <div>
            <SearchResults
                searchResult={searchResult}
                hasSortingOptions
                dispatch={dispatch}
                sortResults={searchState.sortResults}
            />
            {canLoadMore && (
                <Paginator 
                    onLoadMoreClickPrev={
                        () => {
                            trackEvent('Forrige side søketreff markedsadvarsler', `Side ${searchResult.page}`);
                            dispatch(loadMore(searchState, searchResult.page - 1));
                            window.scrollTo(0, 0);
                        }
                    }
                    onLoadMoreClickNext={() => {
                        trackEvent('Neste side søketreff markedsadvarsler', `Side ${searchResult.page}`);
                        dispatch(loadMore(searchState, searchResult.page + 1));
                        window.scrollTo(0, 0);
                    }}
                    onJumpToPageClick={(page) => {
                        trackEvent('Hopp til side markedsadvarsler', `Side ${page}`);
                        dispatch(loadMore(searchState, page));
                        window.scrollTo(0, 0);
                    }}
                    language={language}
                    searchResultPage={searchResult.page}
                    searchResultTotalPages={searchResult.totalPages}
                />                
            )}
        </div>

    );
}

InvestorWarningsSearchResults.propTypes = {
    searchResult: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        searchResult: state.searchResult,
        searchState: searchStateSelector(state)
    };
}

export default connect(mapStateToProps)(InvestorWarningsSearchResults);
