import React from 'react';

interface LoadMoreProps {
    onLoadMoreClick(): void;
    icon: string;
    text: string;
    enabled: boolean;
}

export const LoadMore: React.FC<LoadMoreProps> = (props: LoadMoreProps) => (
    <button
        type="button"
        className={`btn ${props.enabled ? "btn--secondary" : "btn--disabled"}`}
        onClick={() => {
            props.onLoadMoreClick();
        }}
        disabled={!props.enabled}
    >
        <span className={`icon ${props.icon}`}>
            {props.text}
        </span>
    </button>
);
