import React, { FC, useContext } from "react";
import { Provider } from "react-redux";
import Loader from "../../components/loader";
import { LanguageContext, getLanguage } from "../../components/LanguageContext";
import { store } from "../Search/vregSearchStore";
import { useGetStandardReportAreasQuery } from "../Services/VregApi";
import { ApiRequest } from "../Services/ApiRequest";
import { translateLang } from "../../translate";

interface PredefinedReportsProps {
    language: string,
    reportPageUrl: string
}
export const PredefinedReports: FC<PredefinedReportsProps> = (props) =>
    <Provider store={store}  >
        <LanguageContext.Provider value={getLanguage(props.language)}>
            <React.StrictMode>
                <PredefinedReportsInProvider {...props} />
            </React.StrictMode>
        </LanguageContext.Provider>
    </Provider>

const PredefinedReportsInProvider: FC<PredefinedReportsProps> = (props: PredefinedReportsProps) => {
    const languageContext = useContext(LanguageContext);
    const { data, error, isLoading } = useGetStandardReportAreasQuery({ language: props.language } as ApiRequest);

    if (error) {
        return <h2>{translateLang('vreg.reports.errorLoading', languageContext.code)}</h2>
    }

    if (isLoading) {
        return (
            <Loader isLoading languageCode={props.language} />
        )
    }

    return (
        <ul>
            {data?.areas?.map((area) =>
                <li key={area.code} 
                className="mb-3 lg:w-8/12 p-normal bg-black-100 text-bluegreen-500 border-l-4 border-bluegreen-500 font-semibold icon icon--arrow__right icon--position__farright">
                <a href={`${props.reportPageUrl}?area=${area.code}`}>{area.name}</a>
            </li>
            )}
        </ul>);
}

