import React, { FC, useContext } from "react"
import { ReportJsonEntry } from "../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg.Reports"
import { translateLang } from "../../translate"
import { LanguageContext } from "../../components/LanguageContext"

interface ReportEntryProps {
    hit: ReportJsonEntry,
    data: string
}

export const ReportResultItem: FC<ReportEntryProps> = (props: ReportEntryProps) => {
    const languageContext = useContext(LanguageContext);
    return (
        <div className="p-6 bg-white-400 mb-3">
            <div key={props.hit.serviceProvider?.finanstilsynetId}>
                {props.hit.serviceProvider.serviceProviderType &&

                    <div className="text-sm text-yellow-600">
                        {translateLang(`vreg.serviceProvider.types.${props.hit.serviceProvider.serviceProviderType}`, languageContext.code)}
                    </div>
                }

                <h2 className=" text-xl font-semibold listing">
                    <a href={`${props.data}?id=${props.hit.serviceProvider.legalEntityId}`} title={props.hit.serviceProvider.legalEntityName} className=" text-black-400 hover:text-bluegreen-500 uppercase">
                        {props.hit.serviceProvider.legalEntityName}
                    </a>
                </h2>

                <ul className="flex flex-wrap lg:flex-nowrap flex-row mt-2 -mx-3 -mb-3">
                    <li className="flex items-center space-x-1 p-3">
                        <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">corporate_fare</span>
                        <span className="text-yellow-600 uppercase text-base font-semibold">
                            {translateLang(`vreg.entityTypes.${props.hit.serviceProvider.legalEntityType.toLowerCase()}`, languageContext.code)}
                        </span>
                    </li>
                    {props.hit.licence &&
                        <li className="flex items-center space-x-1 p-3">
                            <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">list_alt</span>
                            <span className="text-yellow-600 uppercase text-base font-semibold">
                                {props.hit.licence.licenceTypeName}
                            </span>
                        </li>
                    }
                    {props.hit.serviceProvider.country &&
                        <li className="flex items-center space-x-1 p-3">
                            <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">public</span>
                            <span className="text-yellow-600 uppercase text-base font-semibold">
                                {props.hit.serviceProvider.country}
                            </span>
                        </li>
                    }
                    {props.hit.serviceProvider.county &&
                        <li className="flex items-center space-x-1 p-3">
                            <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">location_pin</span>
                            <span className="text-yellow-600 uppercase text-base font-semibold">
                                {props.hit.serviceProvider.county}
                            </span>
                        </li>
                    }
                </ul>
            </div>

            <div className="block bg-black-100 h-1 my-6" />

            <div className="flex flex-col lg:flex-row lg:flex-wrap lg:-mx-3 -mt-3" >
                {props.hit.serviceProvider.legalEntityNumber && (
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.orgnumber', languageContext.code)}
                        </h2>
                        <p>{props.hit.serviceProvider.legalEntityNumber}</p>
                    </div>
                )}

                {props.hit.serviceProvider.leiCode &&
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.leiCode', languageContext.code)}
                        </h2>
                        <p>{props.hit.serviceProvider.leiCode}</p>
                    </div>
                }

                {!props.hit.serviceProvider.legalEntityId && props.hit.serviceProvider.legalEntityType !== "Person" && props.hit.serviceProvider.finanstilsynetId &&
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            Finanstilsynet Id
                        </h2>
                        <p>{props.hit.serviceProvider.finanstilsynetId}</p>
                    </div>
                }

                {props.hit.licenceOwner && props.hit.licenceOwner.legalEntityName !== props.hit.serviceProvider.legalEntityName &&
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.licenceOwner', languageContext.code)}
                        </h2>
                        <p>{props.hit.licenceOwner.legalEntityName}</p>
                    </div>
                }

                {props.hit.serviceProvider.auditorNumber && (
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.auditorNumber', languageContext.code)}
                        </h2>
                        <p>{props.hit.serviceProvider.auditorNumber}</p>
                    </div>
                )}

                {props.hit.licence.supervisoryAuthority &&
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.supervisoryAuthority', languageContext.code)}
                        </h2>
                        <p>{props.hit.licence.supervisoryAuthority.name}</p>
                    </div>
                }

                {props.hit.borderCrossingActivity &&
                    <div className="py-3 lg:px-3">
                        <h2 className="text-base font-semibold mb-2">
                            {translateLang('vreg.reports.crossBorder', languageContext.code)}
                        </h2>
                        <p>{props.hit.borderCrossingActivity}</p>
                    </div>
                }
                {props.hit.licence.service &&
                    <div className="py-3 lg:px-3">
                    <h2 className="text-base font-semibold mb-2">
                        {translateLang('vreg.reports.service', languageContext.code)}
                    </h2>
                    <p>{props.hit.licence.service.groupName}</p>
                    <p>{props.hit.licence.service.name}</p>
                </div>
                }
            </div>
        </div>
    );
}