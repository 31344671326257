import React, { FC, useContext, useState } from 'react'
import { LanguageContext } from '../LanguageContext';
import { translateLang } from '../../translate';

interface ExpandProps{
    ariaLabelKey: string
    titleKey?: string|null,
    titleString?: string|null,
    children: React.ReactNode | React.ReactNode[];
    expanded?: boolean|null
}

export const ExpandBox: FC<ExpandProps> = (props) => {
    const languageContext = useContext(LanguageContext);
    const [expanded, setExpanded] = useState(props.expanded || false);

    return (
        <div className="w-full bg-white-400 lg:border lg:border-l-4 lg:py-4 lg:px-5 mb-6 lg:border-bluegreen-200" >            
            <button type="button" 
                className={`w-full text-base font-semibold icon ${expanded ? 'icon--close' : 'icon--expand__more' } icon--position__farright hover:text-bluegreen-500`} 
                aria-label={translateLang(props.ariaLabelKey, languageContext.code)}
                onClick={() => setExpanded(!expanded)}
                >                    
                {props.titleKey && translateLang(props.titleKey, languageContext.code)}
                {props.titleString && props.titleString}
            </button>
            {expanded &&
                props.children
            }
        </div>

    );
}