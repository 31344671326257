import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { translateLang } from '../../translate';

export class SortSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items || [],
            showItems: false,
            selectedItem:
                (this.props.items &&
                    this.props.items.find(
                        (item) =>
                            item.id.toString() ===
                            this.props.selectedSorting.sortAlphabetically.toString()
                    )) ||
                this.props.items[0],
        };

        this.selectItem = this.selectItem.bind(this);
    }

    selectItem(item) {
        this.props.onSelectedSort(item.id);
        this.setState({
            selectedItem: item,
            showItems: false,
        });
    }

    render() {
        return (
            <div
                className="flex flex-row space-x-4 items-center"
                aria-label={translateLang('newsarchive.search.sortBy', this.props.language)}
            >
                <span>{translateLang('newsarchive.search.sortBy', this.props.language)}</span>
                {this.state.items.map(
                    (item) =>
                        <div
                            id={`sort_${item.id}`} // If the select contains more than two values, then this id must be changed to i.e "down-{item.id}" and item.id passed into onKeyPressed
                            key={`sort_${item.id}`}
                            className="radiobox--custom"
                        >
                            <input
                                tabIndex="0"
                                type="radio"
                                id={`radio_${item.id}`}
                                name="archivesort"
                                checked={item.id.toString() === this.state.selectedItem.id.toString()}
                                onChange={() => this.selectItem(item)}
                            />
                            <label htmlFor={`radio_${item.id}`} className="font-semibold">
                                {item.value}
                            </label>
                        </div>
                )}
            </div >
        );
    }
}
