import { createSelector } from 'reselect';

const queryStringSelector = (state) => state.searchBox.queryString;
const typeFiltersSelector = (state) => state.typeFilters.items || [];
const dateSelector = (state) => state.dateFilters;
const settingsSelector = (state) => state.settings;
const sortResultsSelector = (state) => state.sortResults;

export const searchStateSelector = createSelector(
    queryStringSelector,
    typeFiltersSelector,
    dateSelector,
    settingsSelector,
    sortResultsSelector,
    (
        queryStringSelector,
        typeFilterItems,
        dateSelector,
        settingsSelector,
        sortResultsSelector
    ) => ({
        queryString: queryStringSelector,
        activeFilters: typeFilterItems
            .filter((item) => item.checked)
            .map((activeItem) => activeItem.id),
        dateFilters: dateSelector,
        lang: settingsSelector.lang,
        sortResults: sortResultsSelector,
    })
);
