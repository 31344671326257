import PropTypes from 'prop-types';
import React from 'react';
import CheckboxListItem from './CheckboxListItem';

const CheckboxList = ({ additionalClass, typefilters, onTypeFilterClick, metaDataFilters, onMetaFilterChange }) => (
    <section>
        <ul className="mb-4 pb-4 border-b border-gray-400" id="search-filter-ul" aria-hidden="false">
            {typefilters.map((typefilter) => (
                <div key={`typefilter_${typefilter.id}`}>
                    <CheckboxListItem
                        additionalClass={additionalClass}
                        checked={typefilter.checked}
                        text={typefilter.name}
                        onClick={() => onTypeFilterClick(typefilter.id, typefilter.name)}
                    />

                    {metaDataFilters?.items.length > 0 && metaDataFilters.items.find(f => f.type === typefilter.type) && (
                        <li className="py-3 flex flex-col space-y-3 pl-11" key={`metafilter${typefilter.id}`}>
                            <div className="flex flex-row space-x-6">
                                {metaDataFilters.items.map((filter) => (
                                    <div className="checkbox--custom" key={`radio_${filter.id}`} >
                                        <input
                                            type="checkbox"
                                            key={`check_${filter.id}`}
                                            value={filter.id}
                                            name="metaDataRadio"
                                            title={filter.name}
                                            id={`check_${filter.id}`}
                                            checked={metaDataFilters.activeFilter !== -1}
                                            onChange={(e) =>
                                                onMetaFilterChange(
                                                    e.target.checked ? Number(e.target.value) : -1,
                                                    e.target.title
                                                )}
                                        />
                                        <label htmlFor={`check_${filter.id}`}>
                                            {filter.name}
                                        </label>
                                    </div>
                                ))}
                            </div>                          
                        </li>
                    )}
                </div>
            ))}


        </ul>
    </section >
);

CheckboxList.propTypes = {
    onTypeFilterClick: PropTypes.func.isRequired,
    typefilters: PropTypes.array.isRequired,
    additionalClass: PropTypes.string,
    metaDataFilters: PropTypes.object,
    onMetaFilterChange: PropTypes.func
};

export default CheckboxList;
