import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import { sortAlphabetically } from '../../investorWarnings/investorWarningsActions';
import { translateLang } from '../../translate';
import { LanguageContext } from '../LanguageContext';
import { SearchResultItem } from './searchResultItem';
import { SortSelect } from './sortSelect';
import { SkeletonLoader } from '../SkeletonLoader';

const SearchResults = ({ searchResult, dispatch, hasSortingOptions, sortResults }) => {

    const language = useContext(LanguageContext);

    const nowShowingFrom = ((searchResult.page - 1) * searchResult.pageSize) + 1;
    const nowShowingTo = searchResult.items.length < searchResult.pageSize ? ((searchResult.page - 1) * searchResult.pageSize) + searchResult.items.length : (searchResult.page * searchResult.pageSize);
    
    if(searchResult.loading){
        return <SkeletonLoader type='list' />
    }

    return (
        <div role="region" aria-label={translateLang('newsarchive.texts.searchResult', language.code)}>
            <div className="bg-white-400 p-6 mb-6">
                <div className='w-full lg:w-11/12 flex flex-col lg:flex-row lg:justify-between space-y-3 lg:space-y-0'>

                    {searchResult && searchResult.items.length > 0 ? (
                        translateLang('newsarchive.texts.totalHitsTemplate', language.code, [nowShowingFrom, nowShowingTo, searchResult.total])
                    )
                        :
                        translateLang('newsarchive.texts.noHits', language.code)
                    }

                    {hasSortingOptions && (
                        <SortSelect
                            items={[
                                { value: translateLang('newsarchive.search.sortByDate', language.code), id: false },
                                { value: translateLang('newsarchive.search.sortByAlphabet', language.code), id: true },
                            ]}
                            onSelectedSort={(id) => {
                                dispatch(sortAlphabetically(id));
                            }}
                            selectedSorting={sortResults}
                            language={language.code}
                        />
                    )}
                </div>
            </div>
            <div className="bg-white-400 p-6 mb-6">
                <div className="w-full lg:w-11/12 -mt-6">
                    {!searchResult.loading &&
                        searchResult.items.map((item) => (
                            <div className='py-6 border-b-4 border-black-100' key={`result_${item.id}`}>
                                <SearchResultItem
                                    name={item.name}
                                    preamble={item.preamble}
                                    published={item.published}
                                    pageType={item.pageType}
                                    url={item.url}
                                    metadata={item.metaData}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

SearchResults.propTypes = {
    searchResult: PropTypes.object.isRequired,
    hasSortingOptions: PropTypes.bool
};

export default SearchResults;
