import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import URLSearchParams from '@ungap/url-search-params';
import ProspectusRegistrySearchBox from './prospectusRegistrySearchBox';
import ProspectusRegistrySearchFilters from './prospectusRegistrySearchFilters';
import ProspectusRegistrySearchResults from './prospectusRegistrySearchResults';
import ProspectusRegistrySearchState from './prospectusRegistrySearchState';
import prospectusRegistryReducer, { initialState } from './prospectusRegistryReducer';
import { window } from '../util/ssrBase';
import { PageIndex } from '../components/pageIndex/PageIndex';
import { getLanguage, LanguageContext } from '../components/LanguageContext';

export const NAME = 'prospectusRegistry';

function populateInitialState(language) {
    if (window.location.search) {
        const params = new URLSearchParams(window.location.search.substring(1));
        initialState.searchBox.queryString = params.get('q') || '';
        initialState.documentTypeFilters.activeFilter = parseInt(params.get('d') || -1, 10);
    }
    initialState.settings.lang = language;
}

export class ProspectusArchive extends React.Component {

    store = null;

    constructor(props) {
        super(props);
        populateInitialState(this.props.language);

        this.store = createStore(prospectusRegistryReducer, initialState, applyMiddleware(thunk));
    }

    render() {
        return (
            <LanguageContext.Provider value={getLanguage(this.props.language)} >
                <Provider store={this.store}>
                    <ProspectusRegistrySearchState />
                    <div className="flex justify-end mb-6">
                        <div className="w-full lg:w-8/12 lg:pl-5">
                            <h1 className="text-2xl lg:text-3xl font-normal leading-h1">{this.props.pageName}</h1>
                            <div className="flex w-full ">
                                <ProspectusRegistrySearchBox />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                        <aside className="w-full lg:w-4/12 relative">
                            <ProspectusRegistrySearchFilters />
                        </aside>

                        <div className="w-full lg:w-8/12">
                            <ProspectusRegistrySearchResults />
                        </div>
                    </div>
                </Provider>
            </LanguageContext.Provider>
        );
    }
}
