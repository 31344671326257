import React, { FC, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiRequest } from "../../Services/ApiRequest";
import { useGetLicenceTypesQuery } from "../../Services/VregApi";
import Loader from "../../../components/loader";
import { LanguageContext } from "../../../components/LanguageContext";
import { CollapsibleCheckboxList } from "./collapsibleCheckboxList";
import { translateLang } from "../../../translate";
import { VregRootState } from "../vregSearchStore";
import { vregSearchActions, vregSearchStateSlice } from "../../Services/VregSearchState";
import { SelectedItems } from "./selectedItems";

export const LicenceTypeFilter: FC = () => {

    const [open, setOpen] = useState(false);
    const languageContext = useContext(LanguageContext);
    const { data, error, isLoading } = useGetLicenceTypesQuery({ language: languageContext.code } as ApiRequest);
    const selectedLicenceTypes = useSelector((state: VregRootState) => state.vregSearchState.selectedLicenceTypes);
    const selectedLicenceTypesCount = selectedLicenceTypes.length;

    const dispatch = useDispatch();

    if(error){
        return (<h2>Feil ved innlasting!</h2>)
    }


    return (
        <div className="w-full bg-white-400 lg:border lg:border-l-4 lg:py-4 lg:px-5 mb-6 lg:border-bluegreen-200">            
            <button type="button"
                onClick={() => setOpen(!open)}
                className={`w-full text-base font-semibold icon ${ open ? "icon--close" : "icon--expand__more"} icon--position__farright hover:text-bluegreen-500`}
                aria-label={translateLang('vreg.filters.licenceType', languageContext.code)}>
                {translateLang('vreg.filters.licenceType', languageContext.code)}


                {selectedLicenceTypesCount > 0 &&
                    <span className="text-bluegreen-500 ml-1" aria-hidden="true">({selectedLicenceTypesCount})</span>
                }
            </button>

            
            {isLoading &&
                <Loader isLoading languageCode={languageContext.code} />
            }

            {selectedLicenceTypes &&
                <SelectedItems helptextKey="" checkedItems={selectedLicenceTypes} checkItem={vregSearchActions.selectLicenceType} />
            }

            {open &&
                <div className="flex flex-col mt-4 divide-y divide-black-200">
                    {data?.licenceGroups.map(licenceGroup =>

                        <CollapsibleCheckboxList 
                            licenceGroup={licenceGroup}
                            onClickAction={vregSearchStateSlice.actions.selectLicenceType}
                            key={licenceGroup.id} 
                            onSelectAllAction={vregSearchStateSlice.actions.toggleAllGroup}
                        />
                    )}

                    {/* <!-- Close filter pane --> */}
                    <section className={`flex pt-6 ${selectedLicenceTypesCount > 0 ? 'justify-between' : 'justify-end'}`} >
                        {selectedLicenceTypesCount > 0 &&
                            <button type="button" className="text-sm font-normal text-left hover:text-bluegreen-500 underline hover:no-underline"
                                onClick={() => {dispatch(vregSearchActions.resetLicenceTypes())}}>
                                {translateLang('vreg.buttons.resetCounties', languageContext.code)}
                            </button> 
                        }
                        <button type="button" 
                            className="text-sm font-normal text-left icon icon--close hover:text-bluegreen-500 underline hover:no-underline"
                            onClick={() => setOpen(!open)}
                            >
                            {translateLang('vreg.buttons.close', languageContext.code)}
                        </button>
                    </section>
                    {/* <!-- // Close filter pane --> */}
                </div>}
        </div>);

}