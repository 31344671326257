import { Component } from 'react';
import { connect } from 'react-redux';
import { initSearch } from './investorWarningsActions';
import { searchStateSelector } from './investorWarningsSearchStateSelector';

class InvestorWarningsSearchState extends Component {
    constructor(props) {
        super(props);
        this.state = { init: true };
    }

    componentDidMount() {
        this.state.init = false;
    }

    render() {
        const { dispatch } = this.props;
        if (!this.state.init) {
            dispatch(initSearch(this.props));
        }
        return null;
    }

    
}

export default connect(searchStateSelector)(InvestorWarningsSearchState);
