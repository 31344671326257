import React,{ FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { vregSearchStateSlice } from "../Services/VregSearchState";
import { LanguageContext } from "../../components/LanguageContext";
import { translateLang } from "../../translate";
import { window } from "../../util/ssrBase";
import { replaceUrlParams } from "../../util/urlHelpers";

export const VregSearchInput: FC = () => {
    const urlSearch = new URLSearchParams(window.location.search.substring(1));    
    const [query, setValue] = useState(urlSearch.get('q') || '');
    
    const dispatch = useDispatch();
    const languageContext = useContext(LanguageContext);

    /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }]*/
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search.substring(1));
        urlSearchParams.set('q', query);
        replaceUrlParams(`?${urlSearchParams}`);        
        dispatch(vregSearchStateSlice.actions.setQuery(query));
    }, [query]);

    return (
        <form className="mb-6 px-6 lg:px-0" onSubmit={(e) => e.preventDefault()} >
            <fieldset>
                <legend className="sr-only">
                    {translateLang('vreg.search.legend', languageContext.code)}                    
                </legend>
                    
                <div className="flex">
                    <label htmlFor="vreg_search_input" className="sr-only">
                        {translateLang('vreg.search.placeholder', languageContext.code)}
                    </label>
                    <input
                        type="search"
                        id="vreg_search_input"
                        className="w-full placeholder:text-black-300"
                        onChange={(v) =>{ 
                            setValue(v.currentTarget.value);                            
                        }}
                        value={query}
                        placeholder={translateLang('vreg.search.placeholder', languageContext.code)}/>
                        
                    <button type="button" onClick={(e) => { e.preventDefault();}} className="btn btn--primary px-6">
                    {translateLang('vreg.search.searchButtonText', languageContext.code)}
                    </button>
                </div>
            </fieldset>
        </form>)
}