import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSearchQuery } from './investorWarningsActions';
import SearchBox from '../components/search/searchBox';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';

class InvestorWarningsSearchBox extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch, searchBox } = this.props;

        return (
            <SearchBox
                initialQuery={searchBox.queryString}
                onSearch={(query, isChanged) => dispatch(setSearchQuery(query, isChanged))}
                searchOnMount
                buttonText={translateLang('globalsearch.buttons.search', this.context.code)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        searchBox: state.searchBox
    };
}

InvestorWarningsSearchBox.propTypes = {
    searchBox: PropTypes.object
};

InvestorWarningsSearchBox.contextType = LanguageContext;

export default connect(mapStateToProps)(InvestorWarningsSearchBox);
